import { useEffect, useState } from "react";
import { LinksFunction } from "@remix-run/node";
import { Link, Form, useActionData, useOutletContext, useNavigate } from "@remix-run/react";
import PushableButtonWithStyles from "../components/PushableButton";
import loginRegisterStyles from "~/styles/login-register.module.css";
import { jwtDecode } from 'jwt-decode'


export const links: LinksFunction = () => {
    return [{ rel: "stylesheet", href: loginRegisterStyles }];
};



export default function Login() {
    const actionData = useActionData<{ error?: string }>();
    const { supabase } = useOutletContext()

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate()

    const signIn = async () => {
        const { data, error } = await supabase.auth.signInWithPassword({
            email,
            password,
        });

        if (error) {
            console.error("Sign in error:", error.message)
        }
        console.log("Sign in success");
        console.log(data);
    };

    useEffect(() => {
        const { subscription: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
            if (session) {
                navigate("/onboarding")

            }
        });
    }, [supabase, navigate]);
    // useEffect(() => {
    //     const { subscription: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
    //         if (session) {
    //             const jwt = jwtDecode(session.access_token);
    //             const userRole = jwt.user_role;
    //             const username = jwt.username;
    //             // You can use userRole here as needed
    //             console.log(userRole);
    //             console.log(username);
    //             switch (userRole) {
    //                 case 'shop_owner':
    //                     navigate(`@${username}/admin`);
    //                     break;
    //                 case 'customer':
    //                     navigate(`@${username}/customer`);
    //                     break;
    //             }

    //         }
    //     });
    // }, [supabase, navigate]);

    

    return (
        <>
            <div className="flex min-h-full flex-1">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <Link to="/">
                                <img
                                    alt="SkillShop"
                                    src="/logo-skillshop-light-bkg.svg"
                                    className="h-10 w-auto"
                                />
                            </Link>
                            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Sign in to your account
                            </h2>
                        </div>

                        <div className="mt-10">
                            <div>
                                <Form className="space-y-6" onSubmit={(e) => {
                                    e.preventDefault();
                                    signIn();
                                }}>
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Email address
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                required
                                                autoComplete="email"
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                            Password
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                required
                                                autoComplete="current-password"
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <input
                                                id="remember-me"
                                                name="remember-me"
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            />
                                            <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                                                Remember me
                                            </label>
                                        </div>

                                        <div className="text-sm leading-6">
                                            <Link to="/reset" className="font-semibold text-[#f0003c] hover:text-[#f0003c]">
                                                Forgot password?
                                            </Link>
                                        </div>
                                    </div>

                                    <div>
                                        <PushableButtonWithStyles className="w-full" type="submit">
                                            Sign In
                                        </PushableButtonWithStyles>
                                    </div>

                                    {actionData?.error && (
                                        <div className="text-red-600 text-sm">{actionData.error}</div>
                                    )}
                                </Form>
                            </div>

                            {/* Add Link to Register */}
                            <div className="mt-6 w-full text-center text-sm">
                                Don't have an account?
                                <Link to="/register" className="font-semibold text-[#f0003c] ml-1">
                                    Register
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        alt=""
                        src="/images/astronaut-login.jpg"
                        className="absolute inset-0 h-full w-full object-cover"
                    />
                </div>
            </div>
        </>
    )
}